import { Box, Grid, Paper, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import React, { useState } from 'react';
import AppHeader from "../AppHeader/AppHeader";
import HomeMenuBar from "./MenuBar";
import CustomerList from "./CustomerList";
import TableView from "./Table";
import ResponsiveDrawer from "../common/ResponsiveSidebar";
import HeaderTicker from "../AppHeader/HeaderTicker";
import IndexMovers from "../IndexMovers/IndexMovers";
import CommingSoon from "../common/Comming";
import { TabContext, TabList } from "@material-ui/lab";
import TraderView from "./TraderView";
import InvestorView from "./InvestorView";
import Etfs from "../ETFS/Etfs";
import Indecies from "../Indecies/Indecies";
import Heatmaps from "../HeatMaps/Heatmaps";
import FutureAndOptions from "../FutureAndOptions/FutureAndOptions";
import IndexMoversSeg from "../IndexMovers/IndexMoversSeg";
import GraphView from "./GraphView";
import MutualFunds from "../mutualFunds/MutualFunds";
import Options from "../Options/Options";
import OptionsOI from "../OptionsOI/OptionsOI";
import CenteredSpiralSquareTable from "../miscellaneous/CenteredSpiralSquareTable";
import InSights from '../insights/InSights'
import OIChangeOptions from "../OptionsOI/OIChangeOptions";
import FandOIndex from "../FutureAndOptions/FandOIndex";
import OptionsIndex from "../OptionsOI/OptionsIndex";
import TrendView from "./TrendView";

const NavabarList = [

  {
    id: 0,
    label: "Equities",
    path: "/equities"
  },
  {
    id: 1,
    label: "Indices",
    path: "/indices",
    subMenu: [
      { value: '0', label: 'NIFTY50', },
      { value: '1', label: 'BANKNIFTY', },
      { value: "2", label: 'ITNIFTY', },
      { value: '3', label: 'FINNIFTY', },
      { value: "4", label: 'MIDCAPSELECT', },
      { value: '5', label: 'NIFTYNEXT50', },
      // { value: '6', label: 'OI Change (s)', },
    ]
  },
  {
    id: 2,
    label: "ETFs",
    path: "/etfs",
    subMenu: [
      { value: "0", label: 'NIFTY BEES', },
      { value: "1", label: 'BANK BEES', },
      { value: "2", label: 'GOLD BEES', },
      { value: "3", label: 'PHARMA BEES', },
      { value: "4", label: 'AUTO BEES', },
      { value: "5", label: 'IT BEES', },
    ]
  },
  {
    id: 3,
    label: "Index Movers",
    path: "/index-movers",
    subMenu: [
      { value: "0", label: 'Indices', },
      { value: "1", label: "Index Top 10's" },
    ]
  },
  {
    id: 4,
    label: "Heat Maps",
    path: "/heat-maps",
    subMenu: [
      { value: "0", label: 'Price%' },
      { value: "1", label: 'Previous Range%' },
      { value: "2", label: 'Trend' },
      { value: "3", label: ' Futures Trend' },
      // { value: "2", label: 'Current Range%', },
      // { value: "3", label: 'Score%', },
      // { value: "4", label: 'Camarilla%', },
      // { value: "5", label: 'Standard PP%', },
      // { value: "6", label: 'Fibonacci PP%', },
      // { value: "7", label: 'CPR', },
      // { value: "8", label: 'Golden Ratio', },
    ]
  },
  // {
  //   id: 5,
  //   label: "Futures",
  //   path: "/futures"
  // },
  {
    id:5,
    label:'F&O',
    path:'/fandO',
    subMenu:[
      {value:'0',label:'Futures'},
      {value:'1',label:'Options'}
    ]
  },
  // {
  //   id: 6,
  //   label: "Options",
  //   path: "/options"
  // },
  {
    id: 6,
    label: "OI Change",
    path: "/optionsOI",
    subMenu:[
      {value:'0',label:'OI Change (Fixed)'},
      {value:'1',label:'OI Change (Dynamic)'}
    ]
  },
  {
    id: 7,
    label: "MISC.",
    path: "/miscellaneous"
  },
  {
    id: 8,
    label: "InSights",
    path: "/insights"
  }
]

const Home = () => {
  const matches = useMediaQuery('(min-width:9px)');
  const [page, setPage] = useState(3)
  const [dataFromChild, setDataFromChild] = useState('');
  const [loading, setLoading] = useState(false);
  const [submenu, setSubmenu] = useState("0");
  const [submenuEtfs, setSubmenuEtfs] = useState("1");
  const [submenuIndecies, setSubmenuIndecies] = useState("0");
  const [submenuHm, setSubmenuHm] = useState("0");
  const [submenuFandO, setSubmenuFandO] = useState("0");
  const [submenuOptions, setSubmenuOptions] = useState("0");
  const handleDataFromChild = (data) => {
    setDataFromChild(data);
  };
  const viewTabs = [
    { value: '1', label: 'Standard View', },
    { value: '2', label: 'Trader View', },
    { value: '3', label: 'Investor View', },
    { value: '4', label: 'Expert View' },
    { value: '5', label: 'Trend View' }
  ]
  const viewTabsForIndices = [
    { value: '1', label: 'Standard View', },
    { value: '2', label: 'Trader View', },
    { value: '3', label: 'Investor View', },
    { value: '4', label: 'Expert View' },
    { value: '5', label: 'Candlestick View' },
    { value: '6', label: 'Trend View' },
  ]
  const [value, setValue] = React.useState('4');
  const [label, setlabel] = React.useState(viewTabs[0].label)

  const handleChange = (event, newValue) => {
    setValue(newValue);
    let a = viewTabs.filter((e, i) => e.value === newValue)
    setlabel(a.length ? a[0].label : viewTabs[0].label)
  };

  const content = () => {
    if (page === 0) {
      return (
        <Grid className="main-dashboard">
          <Grid className="customers-list">
            <CustomerList
              matches={matches}
              sendDataToParent={handleDataFromChild}
              dataFromChild={dataFromChild}
              setDataFromChild={setDataFromChild}
              setLoading={setLoading}
            />
          </Grid>
          <Grid className="tables-block" >
            <Grid className="investers-trading-tabs">
              <TabContext value={value}>
                <TabList className="tabs-grid"
                  onChange={handleChange}
                  aria-label="lab API tabs example">
                  {viewTabs.map((e, i) => (
                    <Tab key={i} className="tab-button" label={e.label} value={e.value} />
                  ))}
                </TabList>
              </TabContext>
            </Grid>
            {value === '1' && <TableView dataFromChild={dataFromChild} loading={loading} setLoading={setLoading} />}
            {value === '2' && <TraderView value={value} dataFromChild={dataFromChild} />}
            {value === '3' && <InvestorView value={value} dataFromChild={dataFromChild} />}
            {value === '4' && <GraphView value={value} dataFromChild={dataFromChild} showTop={true} />}
            {value === '5' && <TrendView value={value} dataFromChild={dataFromChild} showTop={true}/>}
          </Grid>
        </Grid>
      );
    } else if (page === 1) {
      return (
        <Grid className="main-dashboard">
          <Indecies submenuIndecies={submenuIndecies} setSubmenuIndecies={setSubmenuIndecies} tabsList={NavabarList[1].subMenu} viewTabs={viewTabsForIndices} />
        </Grid>
      );
    } else if (page === 2) {
      return (
        <Grid className="main-dashboard">
          <Etfs submenuEtfs={submenuEtfs} setSubmenuEtfs={setSubmenuEtfs} tabsList={NavabarList[2].subMenu} viewTabs={viewTabs} />
        </Grid>
      );
    } else if (page === 3) {
      return (
        <Grid className="main-dashboard">
          <IndexMoversSeg submenu={submenu} />
        </Grid>
      );
    } else if (page === 4) {
      return (
        <Grid className="main-dashboard">
          <Heatmaps submenuHm={submenuHm} setSubmenuHm={setSubmenuHm} tabsList={NavabarList[4].subMenu} />
        </Grid>
      );
    } else if (page === 5) {
      return (
        <Grid className="main-dashboard" style={{ height: `calc(100vh - 130px)` }}>
          {/* <FutureAndOptions /> */}
          <FandOIndex submenuFandO={submenuFandO}/>
        </Grid>
      );
    }
    // else if (page === 6) {
    //   return (
    //     <Grid className="main-dashboard" style={{ height: `calc(100vh - 130px)` }}>
    //       <Options />
    //     </Grid>
    //   );
    // }
    else if (page === 6) {
      return (
        <Grid className="main-dashboard" style={{ height: `calc(100vh - 100px)`, justifyContent: "center" }}>
          {/* <OptionsOI /> */}
          <OptionsIndex submenuOptions={submenuOptions}/>
        </Grid>
      );
    }
    else if (page === 7) {
      return (
        <Grid className="main-dashboard" style={{ height: `calc(100vh - 100px)`, justifyContent: "center" }}>
          <CenteredSpiralSquareTable />
        </Grid>
      );
    }
    else if (page === 8) {
      return (
        <Grid className="main-dashboard" style={{ height: `calc(100vh - 100px)`, }}>
          <InSights />
        </Grid>
      );
    }
    else {
      return (
        <Grid className="main-dashboard">
          <CommingSoon />
        </Grid>
      );
    }

  }
  return <Grid container justifyContent="center" >
    <ResponsiveDrawer mainContent={content(page)} NavabarList={NavabarList} page={page} setPage={setPage}
      submenu={submenu} setSubmenu={setSubmenu} setSubmenuFandO={setSubmenuFandO} setSubmenuOptions={setSubmenuOptions}
      submenuEtfs={submenuEtfs} setSubmenuEtfs={setSubmenuEtfs}
      setSubmenuIndecies={setSubmenuIndecies}
      setSubmenuHm={setSubmenuHm}
      
    />
  </Grid>


}

export default Home;