import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { fetchAllTrendsForExpertView, NiftyPlusPlus } from "../apiService/tickerApiService";
import { ArrowDownward, ArrowUpward, Height } from "@material-ui/icons";
import { Grid } from "@material-ui/core";
import IndiceStripNew from "../common/IndiceStripNew";

function TrendView(props) {
    const { value, dataFromChild, showTop, indices } = props;
    const [speedoMeterData, setSpeedoData] = useState([])
    const { token, companySymbol } = dataFromChild;
    const [allTrends, setAllTrends] = useState([]);

    const fetchDataForSpeedometer = async (token, companySymbol) => {
        const company_speedo_data = await NiftyPlusPlus(companySymbol, token)
        setSpeedoData(company_speedo_data?.data)
    }
    const fetchTrends = async (symbol) => {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const fifteenDaysBefore = dayjs().subtract(365, 'day').format('YYYY-MM-DD');
        const symbolToSend = symbol.includes('&') ? encodeURIComponent(symbol) : symbol;

        try {
            const _trend = await fetchAllTrendsForExpertView(currentDate, fifteenDaysBefore, symbolToSend);
            if (_trend?.data) {
                setAllTrends(_trend?.data);
            } else {
                setAllTrends([]);
            }
        } catch (error) {
            setAllTrends([]);
        }
    };

    const calculateAverages = (data) => {
        const sortedData = data.sort((a, b) => new Date(a.date) - new Date(b.date));

        return sortedData.map((trend) => {
            const avg1 = (trend.dailyPercentage + trend.weeklyPercentage + trend.monthlyPercentage) / 3;
            const avg2 = (trend.monthlyPercentage + trend.quaterlyPercentage + trend.halfyearlyPercentage) / 3;
            const avg3 = (trend.quaterlyPercentage + trend.halfyearlyPercentage + trend.yearlyPercentage) / 3;

            const getArrow = (value) => {
                if (value < 38.2) return <ArrowDownward style={{ color: 'red', fontSize: '18px' }} />;
                if (value > 61.8) return <ArrowUpward style={{ color: 'green', fontSize: '18px' }} />;
                return <Height style={{ rotate: "90deg", color: '#DAA520', fontSize: '18px' }} />;
            };

            return {
                ...trend,
                monthYear: dayjs(trend.date).format("YYYY-MM"), // Extract month-year
                formattedDate: dayjs(trend.date).format("DD-MMM"), // Date format like 01-Jan
                avg1: avg1.toFixed(2),
                avg1Arrow: getArrow(avg1),
                avg2: avg2.toFixed(2),
                avg2Arrow: getArrow(avg2),
                avg3: avg3.toFixed(2),
                avg3Arrow: getArrow(avg3),
            };
        });
    };
    useEffect(() => {
        let intervalId
        if (token && companySymbol) {
            intervalId = setInterval(() => {
                fetchDataForSpeedometer(token, companySymbol)
            }, 4000)
        }
        return () => {
            if (intervalId) {
                setSpeedoData([])
                clearInterval(intervalId)
            }
        }
    }, [token, companySymbol])

    useMemo(() => {
        if (token && companySymbol) {
            fetchDataForSpeedometer(token, companySymbol)
            fetchTrends(companySymbol);
        }
    }, [companySymbol]);

    // Grouping trends by Month-Year
    const trendsWithAverages = calculateAverages(allTrends);
    const groupedTrends = trendsWithAverages.reduce((acc, trend) => {
        if (!acc[trend.monthYear]) {
            acc[trend.monthYear] = [];
        }
        acc[trend.monthYear].push(trend);
        return acc;
    }, {});

    // Sort months in descending order (latest month first)
    const sortedMonths = Object.keys(groupedTrends).sort((a, b) => dayjs(b) - dayjs(a));
    const logohandle = (symbolname) => {
        try {
            return (require(`../../assets/images/CounterLogos/${symbolname}.png`))
        } catch (error) {
            return (null)
        }
    }
    return (
        <div>
            {
               showTop && <Grid className="table-ticker">
                    <Grid className='company-cmp'>
                        <Grid style={{ padding: "2px 5px", backgroundColor: "#fff", marginLeft: "5px", borderRadius: "2px" }}>
                            {/* <img style={{width:"30px",marginLeft:"5px",borderRadius:"5px"}} src={img1} alt=''/> */}
                            <img style={{ maxWidth: "50px", minHeight: "10px", maxHeight: "25px", borderRadius: "2px", objectFit: "cover" }} src={logohandle(dataFromChild?.companySymbol)} alt="" />
                        </Grid>
                        <Grid className="details">
                            <Grid className="details-context px-2 py-1"
                                style={{
                                    color: '#fff',
                                    marginLeft: '0px'
                                }}
                            >
                                {dataFromChild?.companySymbol}
                            </Grid>
                        </Grid>
                        <Grid >
                            <Grid className='pricing m-2'>
                                {speedoMeterData?.daily?.length > 0 &&
                                    <Grid
                                        style={{
                                            color: `${speedoMeterData?.daily[0]?.pre_close <= speedoMeterData?.daily[0]?.currentprice ? 'rgb(51, 146, 73)' : 'rgb(218, 36, 36)'}`,
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor: '#fff',
                                            padding: '0px 13px',
                                            borderRadius: '4px',
                                            //  height:'15px'
                                        }}>
                                        <p className='mr-1'>{speedoMeterData?.daily?.length > 0 ? speedoMeterData?.daily[0]?.currentprice : 0}</p>
                                        <p style={{ fontSize: '11px', color: '#857d7d' }}>{speedoMeterData?.daily?.length > 0 ? (speedoMeterData?.daily[0]?.currentprice - speedoMeterData?.daily[0]?.pre_close)?.toFixed(1) : 0}</p>
                                        <p style={{ fontSize: '11px', color: '#857d7d' }}>{`(${speedoMeterData?.daily?.length > 0 ? (((speedoMeterData?.daily[0]?.currentprice - speedoMeterData?.daily[0]?.pre_close) / speedoMeterData?.daily[0]?.pre_close) * 100)?.toFixed(1) : 0}%)`}</p>
                                        {/* ((ticker - prevDC) / prevDC) * 100 */}
                                    </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className='flex'>
                        <IndiceStripNew showNifty50={true} showBankNifty={true} showITNifty={true} showNIFTYf={true} />
                    </Grid>
                </Grid>
            }
            <div style={{height: `calc(100vh - 170px)`,overflow:'auto'}}>
                {sortedMonths.map((monthYear) => (
                    <div key={monthYear} style={{ marginTop: "10px", border: "1px solid #ccc" }}>
                        <h3 style={{ textAlign: "center", backgroundColor: "#e0e0e0", padding: "10px", fontWeight: 'bold', fontSize: '16px' }}>
                            {dayjs(monthYear).format("MMMM YYYY")}
                        </h3>
                        <div style={{ display: "table", width: "100%", textAlign: "center" }}>
                            {/* Header Row */}
                            <div style={{ display: "table-row", backgroundColor: "#f4f4f4", fontWeight: "bold" }}>
                                <div style={{ display: "table-cell", border: "1px solid #ccc", padding: "5px" }}>Trend</div>
                                {groupedTrends[monthYear].map((trend) => (
                                    <div key={trend.date} style={{ display: "table-cell", border: "1px solid #ccc", padding: "5px" }}>
                                        {trend.formattedDate}
                                    </div>
                                ))}
                            </div>

                            {/* Short-Term Row */}
                            <div style={{ display: "table-row" }}>
                                <div style={{ display: "table-cell", border: "1px solid #ccc", fontWeight: "bold", padding: "5px" }}>
                                    ST
                                </div>
                                {groupedTrends[monthYear].map((trend) => (
                                    <div key={`${trend.date}-st`} style={{ display: "table-cell", border: "1px solid #ccc", padding: "5px" }}>
                                        <span>{trend.avg1Arrow}</span>
                                    </div>
                                ))}
                            </div>

                            {/* Medium-Term Row */}
                            <div style={{ display: "table-row" }}>
                                <div style={{ display: "table-cell", border: "1px solid #ccc", fontWeight: "bold", padding: "5px" }}>
                                    MT
                                </div>
                                {groupedTrends[monthYear].map((trend) => (
                                    <div key={`${trend.date}-mt`} style={{ display: "table-cell", border: "1px solid #ccc", padding: "5px" }}>
                                        <span>{trend.avg2Arrow}</span>
                                    </div>
                                ))}
                            </div>

                            {/* Long-Term Row */}
                            <div style={{ display: "table-row" }}>
                                <div style={{ display: "table-cell", border: "1px solid #ccc", fontWeight: "bold", padding: "5px" }}>
                                    LT
                                </div>
                                {groupedTrends[monthYear].map((trend) => (
                                    <div key={`${trend.date}-lt`} style={{ display: "table-cell", border: "1px solid #ccc", padding: "5px" }}>
                                        <span>{trend.avg3Arrow}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TrendView;
